import React from 'react';
import _ from 'lodash';

import {htmlToReact, markdownify} from '../utils';

export default class SectionContact extends React.Component {
    render() {
        let section = _.get(this.props, 'section');
        return (
            <section id={_.get(section, 'section_id')} className={'block contact-block bg-' + _.get(section, 'bg') + ' outer'}>
              <div className="block-content inner form-content">
                <div className="company-info">
                  {markdownify(_.get(section, 'content'))}
                </div>
                <form name="contactForm" action="/sucesso" method="POST" netlifyHoneypot="bot-field" data-netlify="true" id="contact-form"
                  className="contact-form">
                  <div className="block-header inner-small">
                    {_.get(section, 'title') && 
                    <h2 className="block-title">{_.get(section, 'title')}</h2>
                    }
                    {_.get(section, 'subtitle') && 
                    <p className="block-subtitle">
                      {htmlToReact(_.get(section, 'subtitle'))}
                    </p>
                    }
                  </div>
                  <div className="screen-reader-text">
                    <label>Don't fill this out if you're human: <input name="bot-field" /></label>
                  </div>
                  <div className="form-row">
                    <label htmlFor="name" className="form-label">Nome</label>
                    <input type="text" name="name" className="form-input" placeholder="Nome completo" required/>
                  </div>
                  <div className="form-row">
                    <label htmlFor="email" className="form-label">Email address</label>
                    <input type="email" name="email" className="form-input" placeholder="Email" required/>
                  </div>
                  <div className="form-row">
                    <label htmlFor="telefone" className="form-label">Telefone</label>
                    <input type="tel" name="telefone" className="form-input" placeholder="Telefone" required/>
                  </div>
                  <div className="form-row">
                    <label htmlFor="company" className="form-label">Empresa</label>
                    <input type="text" name="company" className="form-input" placeholder="Empresa" required/>
                  </div>
                  <div className="form-row">
                    <label htmlFor="subject" className="form-label">Assunto</label>
                    <input type="text" name="subject" className="form-input" placeholder="Assunto" required/>
                  </div>
                  <div className="form-row">
                    <label htmlFor="message" className="form-label">Mensagem</label>
                    <textarea name="message" className="form-textarea" rows="5" placeholder="Mensagem" required/>
                  </div>
                  <input type="hidden" name="form-name" value="contactForm" />
                  <div className="form-row form-submit">
                    <button type="submit" className="button large secondary">Enviar</button>
                  </div>
                </form>
              </div>
            </section>
        );
    }
}
